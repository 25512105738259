console.log('trouble-trains-2.0')

const troubleItem = ({train, status, description}) => {
  return (
`<div class="trouble-item">
  <div class="line line--${train} line--large">${train}</div>
  <div class="trouble-info">
    <div class="trouble-status">${status}</div>
    <p class="trouble-description">${description}</p>
  </div>
</div>
`
  )
}

const troubleItemEmpty = (data) => {
  return (
`<div class="trouble-item">
  <div class="trouble-info">
    <p class="trouble-description">There are currently no reported troubles in the subway system.</p>
  </div>
</div>
`
  )
}

fetch('/api/delays')
  .then(response => response.json())
  .then(json => {
    const $troubles = document.querySelector('.troubles')

    if (json.troubles.length > 0) {
      $troubles.innerHTML = json.troubles.map(trouble => troubleItem(trouble)).join('')
    } else {
      $troubles.innerHTML = troubleItemEmpty()
    }
  })